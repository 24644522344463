
const Whoops404 = () => {

  return (
    <main className="main">
      <h2 className="main__h2">404</h2>
      <p className="main__p">Page not found. Sorry!</p>
    </main>
  )
}

export default Whoops404